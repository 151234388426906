/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  useMediaQuery,
  createMuiTheme,
} from "@material-ui/core";
import Constants from "../constant";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../provider/UseContext";
import {
  getPaymentProviders,
  refreshToken,
  verifyToken,
  getService as myService,
} from "../provider/mySabay.js";
import Loading from "../components/loading";
import PaymentConfirmDialogOneTime from "../components/paymentConfirmDialogOneTime";
import PaymentConfirmDialogPreAuth from "../components/paymentConfirmDialogPreAuth";
import CustomDialog from "../components/customDialog";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const SelectPayment = () => {
  const {
    paymentProviderLists,
    setPaymentProviderLists,
    postMessageData,
    setPostMessageData,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    total,
    setTotal,
    isLogin,
    setIsLogin,
    openDialogPreAuth,
    setOpenDialogPreAuth,
    setDisplayLogin,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 420,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const classes = useStyles();
  const history = useHistory();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [summaryHeight, setSummaryHeight] = useState(0);
  const elementRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [
    selectedPaymentMethodIndex,
    setSelectedPaymentMethodIndex,
  ] = useState();
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorServiceCode, setShowErrorServiceCode] = useState(false);

  const loadingTimeOut = 5000;

  useEffect(() => {
    if (display) {
      setTimeout(() => {
        myService().trackPageView({
          documentTitle: "Cashier - Select payment",
        });
      }, 1000);
    }
  }, [display]);

  useEffect(() => {
    if (display) {
      setSummaryHeight(
        elementRef?.current?.getBoundingClientRect()?.height
          ? elementRef?.current?.getBoundingClientRect()?.height + 15
          : 0
      );
    }
  }, [selectedPaymentMethod, paymentProviderLists, display]);

  useEffect(() => {
    const data = queryString.parse(window.location.search);

    if (data?.serviceCode || postMessageData?.serviceCode) {
      setDisplay(true);
    } else {
      return setDisplay(false);
    }

    setIsLoading(true);
    getPaymentProviders(
      data.productId ? data.productId : postMessageData.productId
    )
      .then((res) => {
        setPaymentProviderLists(res);
        setIsLoading(false);
        if (data.productId) {
          setPostMessageData(data);
        }
      })
      .catch((err) => {
        setErrorMessage("Something went wrong, please try again later!");
        console.log(err);
        setIsLoading(false);
      });

    verifyToken()
      .then((res) => {
        setIsLogin(true);
      })
      .catch((err) => {
        refreshToken()
          .then((res) => {
            console.log("refresh token success", res);
            setIsLogin(true);
          })
          .catch((err) => {
            console.log("refresh token fail", err);
            setIsLogin(false);
          });
      });
  }, []);

  const handlePayNow = (payload) => {
    if (payload?.type == "pre-auth") {
      if (isLogin) {
        setOpenDialogPreAuth(true);
      } else {
        history.push("/phone-number-login");
      }
    } else if (payload?.type == "onetime") {
      setOpenDialog(true);
    } else {
      setErrorMessage("Please select a payment method");
      setOpenCustomDialog(true);
    }
  };

  const calculateTotal = () => {
    let total;
    if (
      postMessageData?.currency == "USD" &&
      (selectedPaymentMethod?.issueCurrencies[0] == "SC" ||
        selectedPaymentMethod?.issueCurrencies[0] == "SG")
    ) {
      total = Math.ceil((postMessageData?.amount * myService().usdkhr) / 100);
    } else if (
      postMessageData?.currency == "KHR" &&
      (selectedPaymentMethod?.issueCurrencies[0] == "SC" ||
        selectedPaymentMethod?.issueCurrencies[0] == "SG")
    ) {
      total = Math.ceil(postMessageData?.amount / 100);
    } else {
      total = postMessageData?.amount;
    }
    return total + " " + selectedPaymentMethod?.issueCurrencies[0];
  };

  useEffect(() => {
    setTotal(calculateTotal());
  }, [selectedPaymentMethodIndex]);

  //If loading takes too long thens how the text error instead
  useEffect(() => {
    setTimeout(() => {
      if (!display) {
        setShowErrorServiceCode(true);
      }
    }, loadingTimeOut);
  }, []);

  useEffect(() => {
    setDisplayLogin(true);
  }, []);

  return (
    <Container maxWidth="lg" style={{ marginTop: isSm ? "30px" : "50px" }}>
      {display ? (
        <>
          <Grid container spacing={2}>
            <Grid item md={9} sm={12} xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  {errorMessage !=
                    "Something went wrong, please try again later!" && (
                    <Typography
                      className={classes.mainTitle}
                      style={{ fontSize: i18n.language == "kh" && "22px" }}
                    >
                      {t("Select Payment")}
                    </Typography>
                  )}

                  <Grid container spacing={3}>
                    {errorMessage ==
                      "Something went wrong, please try again later!" && (
                      <Typography
                        style={{ fontSize: "22px", margin: 0, padding: "20px" }}
                        className={classes.mainTitle}
                      >
                        {t(errorMessage)}
                      </Typography>
                    )}

                    {errorMessage !=
                      "Something went wrong, please try again later!" &&
                      paymentProviderLists?.map(
                        (data, index) =>
                          (data.type == "onetime" ||
                            data.type == "pre-auth" ||
                            data.type == "fake") &&
                          data.providers.map((val, index) => (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              key={index}
                              style={{ padding: "6px 12px" }}
                            >
                              {isLoading && (
                                <Skeleton height="70px" width="100%" />
                              )}
                              <div
                                className={classes.paymentItemContainer}
                                style={{
                                  border:
                                    selectedPaymentMethodIndex ==
                                    data.type + index
                                      ? `0.5px solid ${Constants.color.main} `
                                      : "0.5px solid #ECECEC",
                                }}
                                id={`${data.type}-${index}`}
                                onClick={() => {
                                  setSelectedPaymentMethodIndex(
                                    data.type + index
                                  );
                                  setSelectedPaymentMethod(val);
                                  myService().trackEvent({
                                    category: "cashier-select-payment-provider",
                                    action: "tap",
                                    name: val.name,
                                  });
                                }}
                              >
                                <div
                                  className={classes.paymentItemContainerNest}
                                  style={{
                                    display: isLoading ? "none" : "flex",
                                    justifyContent: "space-between",
                                    height: isSm ? "12px" : "32px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {val?.info?.logo ? (
                                      <>
                                        <img
                                          src={val.info.logo}
                                          style={{
                                            width: isSm ? "32px" : "40px",
                                            borderRadius: "50%",
                                            display: imageLoaded
                                              ? "inline-block"
                                              : "none",
                                          }}
                                          onLoad={() => setImageLoaded(true)}
                                        />
                                        <FontAwesomeIcon
                                          style={{
                                            color: "#EF495B",
                                            fontSize: 32,
                                            display: imageLoaded
                                              ? "none"
                                              : "inline-block",
                                            marginRight: "8px",
                                          }}
                                          icon={faUniversity}
                                        />
                                      </>
                                    ) : (
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#EF495B",
                                          fontSize: 32,
                                          marginRight: "8px",
                                        }}
                                        icon={faUniversity}
                                      />
                                    )}
                                    <Typography
                                      className={classes.label}
                                      style={{
                                        fontSize: isSm ? "16px" : "20px",
                                      }}
                                    >
                                      {val.name}
                                    </Typography>
                                  </div>

                                  {selectedPaymentMethodIndex ==
                                    data.type + index && (
                                    <FontAwesomeIcon
                                      style={{
                                        color: "#EF495B",
                                        fontSize: 22,
                                      }}
                                      icon={faCheckCircle}
                                    />
                                  )}
                                </div>
                              </div>
                            </Grid>
                          ))
                      )}
                  </Grid>
                </CardContent>
              </Card>
              {!isSm &&
                errorMessage !=
                  "Something went wrong, please try again later!" && (
                  <div style={{ textAlign: "right" }}>
                    <Button
                      id="pay-now-button"
                      className={classes.button}
                      onClick={() => handlePayNow(selectedPaymentMethod)}
                      style={{
                        fontSize: i18n.language == "kh" && "14px",
                        backgroundColor: !selectedPaymentMethodIndex
                          ? Constants.color.gray
                          : Constants.color.main,
                      }}
                    >
                      {t("CONTINUE")}
                    </Button>
                  </div>
                )}

              {isSm && <div style={{ paddingTop: summaryHeight }}></div>}
            </Grid>
            {errorMessage !=
              "Something went wrong, please try again later!" && (
              <Grid item md={3} sm={12} xs={12}>
                <Card
                  className={`${classes.card} summary-card`}
                  style={{
                    width: isSm && "100%",
                    position: isSm && "fixed",
                    left: isSm && 0,
                    bottom: isSm && 0,
                    borderRadius: isSm && 0,
                  }}
                  ref={elementRef}
                >
                  <CardContent>
                    <Typography
                      className={classes.mainTitle}
                      style={{
                        marginBottom: isSm && "0",
                        fontSize: isSm
                          ? "16px"
                          : i18n.language == "kh"
                          ? "22px"
                          : "24px",
                      }}
                    >
                      {t("Summary")}
                    </Typography>
                    <hr
                      style={{
                        opacity: "0.30",
                        backgroundColor: Constants.color.gray,
                        border: "none",
                        height: "1px",
                        margin: "10px 0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        className={classes.label}
                        style={{ margin: 0, fontSize: isSm ? "15px" : "14px" }}
                      >
                        {postMessageData?.displayName}
                      </Typography>

                      <Typography
                        className={classes.label}
                        style={{ margin: 0, fontSize: isSm ? "15px" : "14px" }}
                      >
                        {postMessageData?.currency} {postMessageData?.amount}
                      </Typography>
                    </div>
                    <hr
                      style={{
                        opacity: "0.30",
                        backgroundColor: Constants.color.gray,
                        border: "none",
                        height: "1px",
                        margin: "10px 0",
                      }}
                    />

                    {selectedPaymentMethod && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: isSm && "0px",
                          }}
                        >
                          <Typography
                            className={classes.mainTitle}
                            style={{
                              marginBottom: isSm && 0,
                              color: "#727272",
                              marginRight: "24px",
                              fontSize: isSm
                                ? "20px"
                                : i18n.language == "kh"
                                ? "20px"
                                : "22px",
                            }}
                          >
                            {t("TOTAL")}
                          </Typography>

                          <Typography
                            className={classes.mainTitle}
                            style={{
                              marginBottom: isSm && 0,
                              fontSize: isSm ? "20px" : "22px",
                            }}
                          >
                            {total}
                          </Typography>
                        </div>
                        {isSm && (
                          <Button
                            id="pay-now-button"
                            className={classes.button}
                            onClick={() => {
                              handlePayNow(selectedPaymentMethod);
                              myService().trackEvent({
                                category: "cashier-continue-payment",
                                action: "tap",
                              });
                            }}
                            style={{
                              backgroundColor: !selectedPaymentMethodIndex
                                ? Constants.color.gray
                                : Constants.color.main,
                              width: "100%",
                              marginTop: "12px",
                            }}
                          >
                            {t("CONTINUE")}
                          </Button>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>

          {openDialogPreAuth && (
            <PaymentConfirmDialogPreAuth
              openDialog={openDialogPreAuth}
              setOpenDialog={setOpenDialogPreAuth}
              selectedPaymentMethod={selectedPaymentMethod}
              postMessageData={postMessageData}
              openCustomDialog={openCustomDialog}
              setOpenCustomDialog={setOpenCustomDialog}
            />
          )}

          {openDialog && (
            <PaymentConfirmDialogOneTime
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              selectedPaymentMethod={selectedPaymentMethod}
              postMessageData={postMessageData}
              setErrorMessage={setErrorMessage}
              setOpenCustomDialog={setOpenCustomDialog}
            />
          )}

          {errorMessage && (
            <CustomDialog
              openCustomDialog={openCustomDialog}
              setOpenCustomDialog={setOpenCustomDialog}
              text={errorMessage}
            />
          )}
        </>
      ) : !showErrorServiceCode ? (
        <Loading />
      ) : (
        <Card className={classes.card}>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.mainTitle}
              style={{ margin: 0, fontSize: i18n.language == "kh" && "22px" }}
            >
              {t(
                "The Service Code provided is not valid. Please make sure you have the correct Service Code."
              )}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 0px 5px #00000029",
    borderRadius: "15px",
    background: "#FFFFFF",
    "& .MuiCardContent-root": {
      padding: "40px",
    },
  },
  mainTitle: {
    color: "#D63447",
    fontWeight: "550",
    fontSize: Constants.fontSize.title,
    marginBottom: "20px",
  },
  label: {
    color: "#727272",
    marginLeft: "16px",
    fontSize: Constants.fontSize.text,
  },
  paymentItemContainer: {
    border: "0.5px solid #ECECEC",
    boxShadow: "0px 0px 0px #00000029",
    borderRadius: "6px",
    transition: "0.30s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
  paymentItemContainerNest: {
    padding: "23px 13px",
    display: "flex",
    alignItems: "center",
    "& p": {
      fontWeight: 500,
    },
  },
  button: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "30px",
    marginTop: "15px",
    padding: "6px 28px",
    "&:hover": {
      // background: "green !important",
      transition: "0.30s",
      opacity: "0.9 !important",
    },
  },
}));

export default SelectPayment;

/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Constants from "../constant";

const useStyles = makeStyles((theme) => ({
  loadingStyle: {
    margin: "10px auto 15px auto",
    display: "block",
    color: Constants.color.main,
  },
}));
const LoadingOverlay = (props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2000,
        backgroundColor: "rgba(0,0,0,.5)",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          {...props}
          thickness={6}
          // color="secondary"
          className={classes.loadingStyle}
        />
      </div>
    </div>
  );
};

export default LoadingOverlay;

import React, { useMemo, useState, useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Header from "./components/header";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

import SelectPayment from "./containers/selectPayment";
import PhoneNumberLogin from "./containers/phoneNumberLogin";
import MySabayLogin from "./containers/mySabayLogin";
import { UserContext } from "./provider/UseContext";
import i18next from "./i18n";
const THEME = createMuiTheme({
  typography: {
    fontFamily: `Rajdhani, 'Kantumruy', 'sans-serif'`,
    button: {
      fontFamily: `Rajdhani, 'Kantumruy'`,
    },
  },
});

const App = () => {
  // Have to fake the default value for skeleton loading
  const [paymentProviderLists, setPaymentProviderLists] = useState([
    { type: "fake", providers: [1, 2, 3, 4, 5, 6] },
  ]);
  const [postMessageData, setPostMessageData] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [myService, setMyService] = useState();
  const [total, setTotal] = useState();
  const [userProfile, setUserProfile] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [displayLogin, setDisplayLogin] = useState(true);
  const [openDialogPreAuth, setOpenDialogPreAuth] = useState(false);

  const providerValue = useMemo(
    () => ({
      paymentProviderLists,
      setPaymentProviderLists,
      postMessageData,
      setPostMessageData,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      myService,
      setMyService,
      total,
      setTotal,
      userProfile,
      setUserProfile,
      isLogin,
      setIsLogin,
      displayLogin,
      setDisplayLogin,
      openDialogPreAuth,
      setOpenDialogPreAuth,
    }),
    [
      paymentProviderLists,
      setPaymentProviderLists,
      postMessageData,
      setPostMessageData,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      myService,
      setMyService,
      total,
      setTotal,
      userProfile,
      setUserProfile,
      isLogin,
      setIsLogin,
      displayLogin,
      setDisplayLogin,
      openDialogPreAuth,
      setOpenDialogPreAuth,
    ]
  );

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang == "en") {
      i18next.changeLanguage("en");
    } else if (lang == "kh") {
      i18next.changeLanguage("kh");
    } else {
      i18next.changeLanguage("en");
    }
  }, []);

  return (
    <UserContext.Provider value={providerValue}>
      <BrowserRouter>
        <React.Suspense>
          <ThemeProvider theme={THEME}>
            <Header />
            <Switch>
              <Route exact path="/">
                <SelectPayment />
              </Route>
              <Route path="/phone-number-login">
                <PhoneNumberLogin />
              </Route>
              <Route path="/my-sabay-login">
                <MySabayLogin />
              </Route>
            </Switch>
          </ThemeProvider>
        </React.Suspense>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;

import React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "./loadingOverlay";

const CountDownTimer = (props) => {
  const { timeoutSecond = 0 } = props;
  const [seconds, setSeconds] = useState(timeoutSecond);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        props?.timeout();
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (props.completed) {
      setSeconds(0);
    }
  }, [props]);

  return <>{seconds === 0 ? null : <LoadingOverlay />}</>;
};

export default CountDownTimer;

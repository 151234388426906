/* eslint-disable */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthLg": {
      width: "100%",
    },
  },
  mainTitle: {
    color: "#D63447",
    fontWeight: "550",
    fontSize: Constants.fontSize.title,
    marginBottom: "20px",
  },
  button: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
    padding: "7px 30px",
    margin: "10px",
  },
}));

export default function SuccessPaymentDialog({
  openSuccessPaymentDialog,
  setOpenSuccessPaymentDialog,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={openSuccessPaymentDialog}
      onClose={() => setOpenSuccessPaymentDialog(false)}
      className={classes.root}
      disableBackdropClick={true}
    >
      <div style={{ padding: "40px", textAlign: "center" }}>
        <FontAwesomeIcon
          style={{
            color: "#2CB46B",
            fontSize: 42,
          }}
          icon={faCheckCircle}
        />
        <Typography
          className={classes.mainTitle}
          style={{
            color: "#727272",
            fontSize: "30px",
            marginTop: "12px",
            marginBottom: 0,
          }}
        >
          {t("Payment Successful")}
        </Typography>

        <Typography
          className={classes.label}
          style={{ color: Constants.color.gray, textAlign: "center" }}
        >
          {t("Thank you!")}
        </Typography>
      </div>
    </Dialog>
  );
}

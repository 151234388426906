/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  createMuiTheme,
  Grid,
} from "@material-ui/core";
import Constants from "../constant";
import sabayLogo from "../assets/images/Sabay_BW_H_1.png";
import { useHistory } from "react-router-dom";
import { fetchProfile, getService as myService } from "../provider/mySabay";
import { UserContext } from "../provider/UseContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-light-svg-icons";
import Drawer from "./drawer";
import CustomDialog from "../components/customDialog";
import i18next from "../i18n";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 420,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Constants.color.main,
    "& .MuiListItem-root": {
      padding: "8px 0px 8px 0px",
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiListItem-button": {
      "&:focus": {
        background: "transparent",
        outline: "none",
      },
    },
  },
  logo: {
    cursor: "pointer",
  },
  appBar: {
    backgroundColor: Constants.color.main,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    height: 80,
    justifyContent: "center",
  },
  label: {
    color: "#FFF",
    fontSize: Constants.fontSize.text + 2,
  },
  button: {
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
  },
}));

const options = [
  { value: "en", label: "EN" },
  { value: "kh", label: "ខ្មែរ" },
];

const TopBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "en",
    label: "EN",
  });
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const {
    userProfile,
    setUserProfile,
    isLogin,
    setIsLogin,
    setDisplayLogin,
  } = useContext(UserContext);

  useEffect(() => {
    if (isLogin) {
      setTimeout(() => {
        fetchProfile()
          .then((res) => {
            setUserProfile(res);
          })
          .catch((err) => {
            setErrorMessage(
              "Unexpected error while attempting to fetch your profile."
            );
            setOpenCustomDialog(true);
          });
      }, 100);
    }
  }, [isLogin]);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang !== null) {
      setSelectedOption({
        value: lang,
        label: lang.toLocaleUpperCase(),
      });
    }
  }, []);

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUseAnotherAccount = () => {
    setIsLogin(false);
    localStorage.clear();
    setUserProfile("");
    history.push({
      pathname: "/phone-number-login",
      state: "FROM_USE_ANOTHER_ACCOUNT_BTN",
    });
    handleClose();
    setDisplayLogin(true);
  };

  const handleChangeLanguage = (e) => {
    setSelectedOption({
      value: e.value,
      label: e.label,
    });
    setMenuIsOpen(false);
    i18next.changeLanguage(e.value);
    localStorage.setItem("language", e.value);
    myService().trackEvent({
      category: "cashier-change-language",
      action: "tap",
      name: e.value,
    });
  };

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className={classes.rowCenter}>
        <Typography>{props.data.label}</Typography>
      </div>
    </Option>
  );

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon
            color="white"
            style={{ fontSize: 24 }}
            icon={menuIsOpen ? faAngleUp : faAngleDown}
          />
        </components.DropdownIndicator>
      )
    );
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      backgroundColor: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected && Constants.color.main,
      color: state.isSelected ? "#FFF" : "#000",
    }),
  };

  return (
    <div className={clsx(classes.root)}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            src={sabayLogo}
            height="50"
            alt="sabay"
            className={classes.logo}
            onClick={() => history.push("")}
          />
          {!userProfile && (
            <div style={{ width: 60, cursor: "pointer", marginRight: "6px" }}>
              <Select
                value={{
                  label: (
                    <div>
                      <Typography style={{ color: "#FFF" }}>
                        {selectedOption.label}
                      </Typography>
                    </div>
                  ),
                  value: selectedOption.value,
                }}
                onChange={(e) => handleChangeLanguage(e)}
                autoFocus={false}
                options={options}
                hideSelectedOptions={false}
                isSearchable={false}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                components={{
                  Option: IconOption,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                styles={style}
              />
            </div>
          )}

          {userProfile && (
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isSm ? (
                <Grid container>
                  <Grid
                    item
                    md={2}
                    xs={12}
                    sm={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isSm && "flex-end",
                    }}
                  >
                    <div
                      style={{
                        width: 60,
                        cursor: "pointer",
                        marginRight: "6px",
                      }}
                    >
                      <Select
                        value={{
                          label: (
                            <div>
                              <Typography style={{ color: "#FFF" }}>
                                {selectedOption.label}
                              </Typography>
                            </div>
                          ),
                          value: selectedOption.value,
                        }}
                        onChange={(e) => handleChangeLanguage(e)}
                        autoFocus={false}
                        options={options}
                        hideSelectedOptions={false}
                        isSearchable={false}
                        onMenuOpen={() => setMenuIsOpen(true)}
                        onMenuClose={() => setMenuIsOpen(false)}
                        components={{
                          Option: IconOption,
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        styles={style}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    md={5}
                    xs={12}
                    sm={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isSm && "flex-end",
                    }}
                  >
                    <Typography
                      className={classes.label}
                      style={{ fontSize: i18n.language == "kh" && "16px" }}
                    >
                      {t("Logged in as:")}{" "}
                      <span style={{ fontSize: "18px" }}>
                        {userProfile?.userID}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item md={5} xs={12} sm={12}>
                    <div
                      style={{
                        borderLeft: isSm ? "none" : "1px solid #FFF",
                        padding: "0 0 0 9px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className={classes.button}
                        style={{
                          background: isSm ? "none" : "#FFF",
                          color: isSm ? "#FFF" : Constants.color.main,
                          border: isSm
                            ? "none"
                            : `1px solid ${Constants.color.main}`,
                          padding: isSm ? "5px 0" : "5px 22px",
                          whiteSpace: "nowrap",
                          textDecoration: isSm ? "underline" : "inherit",
                          fontSize: i18n.language == "kh" && "14px",
                        }}
                        onClick={() => {
                          handleUseAnotherAccount();
                        }}
                      >
                        {t("Use Another Account")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <div
                    style={{ width: 60, cursor: "pointer", marginRight: "6px" }}
                  >
                    <Select
                      value={{
                        label: (
                          <div>
                            <Typography style={{ color: "#FFF" }}>
                              {selectedOption.label}
                            </Typography>
                          </div>
                        ),
                        value: selectedOption.value,
                      }}
                      onChange={(e) => handleChangeLanguage(e)}
                      autoFocus={false}
                      options={options}
                      hideSelectedOptions={false}
                      isSearchable={false}
                      onMenuOpen={() => setMenuIsOpen(true)}
                      onMenuClose={() => setMenuIsOpen(false)}
                      components={{
                        Option: IconOption,
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      styles={style}
                    />
                  </div>
                  <Drawer
                    userId={userProfile?.userID}
                    callback={handleUseAnotherAccount}
                  />
                </>
              )}
            </div>
          )}
        </Toolbar>
      </AppBar>

      <CustomDialog
        openCustomDialog={openCustomDialog}
        setOpenCustomDialog={setOpenCustomDialog}
        text={errorMessage}
      />
    </div>
  );
};

export default TopBar;

/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { UserContext } from "../provider/UseContext";
import {
  getPayload,
  payWithPreAuth,
  getInvoiceById,
  getService as myService,
} from "../provider/mySabay.js";
import Constants from "../constant";
import Loading from "../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import LoadingOverlay from "./loadingOverlay";
import CountDownTimerInSeconds from "./countDownTimerInSeconds";
import CustomDialog from "../components/customDialog";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    color: "#D63447",
    fontWeight: "550",
    fontSize: Constants.fontSize.title,
    marginBottom: "20px",
  },
  label: {
    color: "#727272",
    fontSize: Constants.fontSize.text,
    textAlign: "left",
    paddingBottom: "10px",
  },
  button: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
    padding: "7px 30px",
    margin: "10px",
  },
  buttonCancel: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    padding: "7px 30px",
    margin: "10px",
    background: "#FFF",
    color: Constants.color.main,
    border: `1px solid ${Constants.color.main}`,
    "&:hover": {
      backgroundColor: "#F5F5F5 !important",
    },
  },
}));

export default function PaymentConfirmDialogPreAuth({
  openDialog,
  setOpenDialog,
  selectedPaymentMethod,
  postMessageData,
}) {
  const classes = useStyles();
  const { total } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const [payload, setPayload] = useState();
  const [loading, setLoading] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [preAuth, setPreAuth] = useState();
  const timer = useRef(null);
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [showMainDialog, setShowMainDialog] = useState(true);
  const isXs = useMediaQuery(useTheme().breakpoints.down("sm"));

  const timeoutSecond = 30;
  const delayPingMilliseconds = 2000;

  useEffect(() => {
    console.log(selectedPaymentMethod);
    if (postMessageData) {
      getPayload(
        selectedPaymentMethod.id,
        postMessageData.displayName,
        postMessageData.packageId,
        postMessageData.packageCode,

        selectedPaymentMethod.issueCurrencies[0] === "SC" ||
          selectedPaymentMethod.issueCurrencies[0] === "SG"
          ? parseFloat(
              Math.ceil((postMessageData.amount * myService().usdkhr) / 100)
            )
          : postMessageData.amount,

        selectedPaymentMethod.issueCurrencies[0]
      )
        .then((res) => {
          console.log("payload res:", res);
          setPayload(res);
        })
        .catch((err) => {
          setShowMainDialog(false);
          setOpenCustomDialog(true);
          setErrorMessage("Something went wrong, please try again.");
        });
    }
  }, []);

  const checkInvoice = (invoiceId) => {
    getInvoiceById(invoiceId)
      .then((res) => {
        if (res.ssnTxHash) {
          startStopPing({ completedPayment: true });
          setLoading(false);
          setIsPaymentSuccessful(true);
          setTimeout(() => {
            setOpenDialog(false);
            window.close();
          }, 2000);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowMainDialog(false);
        setOpenCustomDialog(true);
        setErrorMessage("Something went wrong, please try again.");
      });
  };

  const handleClickConfirmPayment = (payload) => {
    setLoading(true);
    payWithPreAuth(payload)
      .then((res) => {
        startStopPing(payload.invoiceId);
        setPreAuth(res);
        setLoading(false);
      })
      .catch((err) => {
        setShowMainDialog(false);
        setOpenCustomDialog(true);
        setLoading(false);
        if (err?.response?.data?.title) {
          setErrorMessage(err.response.data.title);
        } else {
          setErrorMessage("Something went wrong, please try again.");
        }
      });
  };

  const startStopPing = async (res) => {
    if (!timer.current) {
      timer.current = setInterval(() => {
        if (res) {
          checkInvoice(res);
        }
      }, delayPingMilliseconds);
    } else {
      clearInterval(timer.current);
      timer.current = null;
      if (!res?.completedPayment) {
        setOpenCustomDialog(true);
        setErrorMessage("Unsuccessful payment");
      }
    }
  };

  return (
    <>
      <Dialog
        style={{ display: showMainDialog ? "block" : "none" }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        disableBackdropClick={true}
      >
        {!isPaymentSuccessful ? (
          <div style={{ padding: "40px" }}>
            <Typography
              className={classes.mainTitle}
              style={{
                fontSize: i18n.language == "kh" ? "28px" : "30px",
                marginTop: "10px",
              }}
            >
              {t("Payment Confirmation")}
            </Typography>

            <Typography
              className={classes.label}
              style={{ color: "#000000", textAlign: "center" }}
            >
              {t("Are you sure you want to pay")} {total}?
            </Typography>

            {preAuth && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CountDownTimerInSeconds
                  timeout={() => {
                    startStopPing();
                  }}
                  completed={isPaymentSuccessful}
                  timeoutSecond={timeoutSecond}
                />
              </div>
            )}

            {errorMessage && !preAuth && <p>{t(errorMessage)}</p>}

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
                flexDirection: isXs ? "column" : "row",
              }}
            >
              <Button
                id="cancel-button"
                className={classes.buttonCancel}
                onClick={() => {
                  myService().trackEvent({
                    category: "cashier-cancel-confirm-payment",
                    action: "tap",
                  });
                  setOpenDialog(false);
                }}
                style={{ fontSize: i18n.language == "kh" && "14px" }}
              >
                {t("Cancel")}
              </Button>

              {!payload ? (
                <Loading />
              ) : (
                <Button
                  id="confirm-button"
                  style={{
                    fontSize: i18n.language == "kh" && "14px",
                    marginTop: isXs ? "0" : "10px",
                  }}
                  className={classes.button}
                  onClick={() => {
                    myService().trackEvent({
                      category: "cashier-confirm-payment",
                      action: "tap",
                    });
                    handleClickConfirmPayment(payload);
                  }}
                >
                  {t("Confirm")}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div style={{ padding: "40px", textAlign: "center" }}>
            <FontAwesomeIcon
              style={{
                color: "#2CB46B",
                fontSize: 42,
              }}
              icon={faCheckCircle}
            />
            <Typography
              className={classes.mainTitle}
              style={{
                color: "#727272",
                fontSize: "30px",
                marginTop: "12px",
                marginBottom: 0,
              }}
            >
              {t("Payment Successful")}
            </Typography>

            <Typography
              className={classes.label}
              style={{ color: Constants.color.gray, textAlign: "center" }}
            >
              {t("Thank you!")}
            </Typography>
          </div>
        )}
      </Dialog>
      {loading && <LoadingOverlay />}

      <CustomDialog
        openCustomDialog={openCustomDialog}
        setOpenCustomDialog={setOpenCustomDialog}
        text={errorMessage}
        callback={() => setOpenDialog(false)}
      />
    </>
  );
}

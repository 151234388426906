import React from "react";
import { useState, useEffect } from "react";
import Constants from "../constant";

const CountDownTimer = (props) => {
  const { initialMinute = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          props?.timeout();
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (props.completed) {
      setMinutes(0);
      setSeconds(0);
    }
  }, [props]);

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <p style={{ color: Constants.color.main, padding: 20, margin: 0 }}>
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </div>
  );
};

export default CountDownTimer;

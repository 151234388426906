/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import Loading from "../components/loading";
import mySabay from "../assets/images/logo/mysabay.png";
import { useHistory } from "react-router-dom";
import Constants from "../constant";
import { loginWithMySabay, fetchProfile } from "../provider/mySabay.js";
import { UserContext } from "../provider/UseContext";
import ErrorText from "../components/errorText";
import { useTranslation } from "react-i18next";
import { getService as myService } from "../provider/mySabay.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $notchedOutline": {
      borderColor: Constants.color.main,
      transition: "0.3s",
    },
    "&$focused $notchedOutline": {
      border: `1px solid ${Constants.color.main}`,
    },
  },
  card: {
    boxShadow: "0px 0px 5px #00000029",
    borderRadius: "15px",
    background: "#FFFFFF",
    "& .MuiCardContent-root": {
      padding: "40px",
    },
  },
  mainTitle: {
    color: "#D63447",
    fontWeight: "550",
    fontSize: Constants.fontSize.title,
    marginBottom: "20px",
  },
  label: {
    color: "#727272",
    fontSize: Constants.fontSize.text,
    textAlign: "left",
    paddingBottom: "10px",
  },
  paymentItemContainer: {
    border: "0.5px solid #C4C4C4",
    boxShadow: "0px 0px 5px #00000029",
    borderRadius: "6px",
    cursor: "pointer",
  },
  paymentItemContainerNest: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    paddingLeft: 8,
    fontSize: Constants.fontSize.text,
  },
  textfield: {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
    },
    "&:hover": {
      border: "none",
      border: "none",
      boxShadow: "0px 0px 0px #00000029",
    },
    "& .MuiInputBase-input": {
      fontSize: "21px",
      fontFamily: "Rajdhani",
      color: Constants.color.text,
      fontWeight: 600,
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px",
    },
  },
  textfieldPassword: {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
    },
    "&:hover": {
      border: "none",
      border: "none",
      boxShadow: "0px 0px 0px #00000029",
    },
    "& .MuiInputBase-input": {
      fontSize: "30px",
      fontFamily: "Rajdhani",
      color: Constants.color.text,
      fontWeight: 600,
      "&::placeholder": {
        fontSize: "22px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
  },
  button: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    marginTop: "15px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
    padding: "7px 16px",
  },
  loadingStyle: {
    margin: "10px auto 15px auto",
    width: "24px !important",
    height: "24px !important",
    display: "block",
  },
  focused: {},
  notchedOutline: {
    borderColor: Constants.color.lightGray,
  },
}));

const PhoneNumberLogin = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [userLoginCredential, setUserLoginCredential] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const history = useHistory();

  const {
    paymentProviderLists,
    setPaymentProviderLists,
    postMessageData,
    setPostMessageData,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setUserProfile,
    setIsLogin,
    openDialogPreAuth,
    setOpenDialogPreAuth,
  } = useContext(UserContext);

  useEffect(() => {
    myService().trackPageView({
      documentTitle: "Cashier - Login by MySabay",
    });
  }, []);

  const fetchUserProfile = () => {
    fetchProfile()
      .then((res) => {
        setUserProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoginMySabay = ({ username, password }) => {
    if (!username == "" && !password == "") {
      setLoading(true);
      loginWithMySabay(username, password)
        .then((res) => {
          setLoading(false);
          setOpenDialogPreAuth(true);
          fetchUserProfile();
          setIsLogin(true);
          history.push("/");
        })
        .catch((err) => {
          setLoading(false);
          setValidationMessage("Invalid username or password");
        });
    } else {
      setValidationMessage("Username or password can't be empty");
    }
  };

  const handleKeyDown = (e, userLoginCredential) => {
    if (e.key === "Enter") {
      setValidationMessage("");
      handleLoginMySabay(userLoginCredential);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      <Card className={classes.card}>
        <CardContent style={{ textAlign: "center" }}>
          <img src={mySabay} style={{ width: "75px", borderRadius: "50%" }} />
          <Typography
            className={classes.mainTitle}
            style={{
              fontSize: i18n.language == "kh" ? "28px" : "30px",
              marginTop: "10px",
            }}
          >
            {t("Sign In")}
          </Typography>

          <TextField
            placeholder={t("Username")}
            variant="outlined"
            className={classes.textfield}
            onChange={(e) => {
              setUserLoginCredential({
                ...userLoginCredential,
                username: e.target.value,
              });
              setValidationMessage("");
            }}
            onKeyDown={(e) => handleKeyDown(e, userLoginCredential)}
            InputProps={{
              id: "username-input",
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
              },
            }}
          />

          <TextField
            type="password"
            style={{ marginTop: "10px" }}
            placeholder={t("Password")}
            variant="outlined"
            className={classes.textfieldPassword}
            onChange={(e) => {
              setUserLoginCredential({
                ...userLoginCredential,
                password: e.target.value,
              });
              setValidationMessage("");
            }}
            onKeyDown={(e) => handleKeyDown(e, userLoginCredential)}
            InputProps={{
              id: "password-input",
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
              },
            }}
          />

          {validationMessage && <ErrorText text={validationMessage} />}

          {loading && <Loading />}
          <div style={{ textAlign: "right" }}>
            <Button
              id="sign-in-button"
              className={classes.button}
              onClick={() => {
                myService().trackEvent({
                  category: "cashier-sign-in-by-mysabay-button",
                  action: "tap",
                });
                handleLoginMySabay(userLoginCredential);
              }}
              style={{ fontSize: i18n.language == "kh" ? "14px" : "16px" }}
            >
              {t("Sign In")}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PhoneNumberLogin;

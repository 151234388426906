import MS from "my-sabay-js-sdk";
import Constants from "../constant";
import queryString from "query-string";

const qs = queryString.parse(window.location.search);

const configure = new MS.Configure(
  qs.serviceCode,
  Constants.env == "production" ? false : true
);
const service = new MS.Service(configure);

export const getService = () => {
  return service;
};

export const loginWithPhone = (phone) => {
  return new Promise((resolve, reject) => {
    service
      .login(phone)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitOTP = (code) => {
  const tmpPhone = localStorage.getItem("tmp-phone");
  return new Promise((resolve, reject) => {
    service
      .verify(tmpPhone, code)
      .then((response) => {
        localStorage.removeItem("tmp-phone");
        localStorage.setItem("token", JSON.stringify(response.accessToken));
        localStorage.setItem(
          "refresh-token",
          JSON.stringify(response.refreshToken)
        );
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginWithMySabay = (username, password) => {
  return new Promise((resolve, reject) => {
    const accessToken = JSON.parse(localStorage.getItem("token"));
    if (accessToken) {
      service.setAccessToken(accessToken);
    }
    service
      .loginMySabay(username, password)
      .then((response) => {
        localStorage.setItem("token", JSON.stringify(response.accessToken));
        localStorage.setItem(
          "refresh-token",
          JSON.stringify(response.refreshToken)
        );
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginWithFacebook = (facebookResponse) => {
  return new Promise((resolve, reject) => {
    service
      .loginFacebook(facebookResponse.tokenDetail.accessToken)
      .then((response) => {
        console.log("app response loginFacebook: ", response);
        localStorage.setItem("token", JSON.stringify(response.accessToken));
        localStorage.setItem(
          "refresh-token",
          JSON.stringify(response.refreshToken)
        );
        resolve(response);
      })
      .catch((error) => {
        console.log("app error loginFacebook:", error);
        reject(error);
      });
  });
};

export const getPaymentProviders = (productId) => {
  return new Promise((resolve, reject) => {
    service
      .getProviders(productId)
      .then((response) => {
        console.log("app response getProviders ", response);
        resolve(response);
      })
      .catch((error) => {
        console.log("app error getProviders:", error);
        reject(error);
      });
  });
};

export const getPayload = (
  paymentProviderId,
  displayName,
  packageId,
  packageCode,
  amount,
  currency
) => {
  const items = [
    {
      displayName: displayName,
      packageId: packageId,
      packageCode: packageCode,
    },
  ];

  return new Promise((resolve, reject) => {
    service
      .getPayload({
        paymentProviderId: paymentProviderId,
        items,
        amount: Number.parseFloat(amount),
        notes: "Buy diamonds",
        ssnTxHash: "",
        paymentProvider: "",
        currency: currency,
      })
      .then((response) => {
        console.log("response getpayload: ", response);
        resolve(response);
      })
      .catch((error) => {
        console.log("get payload error", error);
        reject(error);
      });
  });
};

export const oneTimeHTML = (payload) => {
  return service.oneTimeHTML(payload);
};

export const loginAsGuest = (visitorId) => {
  return new Promise((resolve, reject) => {
    service
      .loginGuest(visitorId)
      .then((response) => {
        console.log("app response loginGuest ", response);
        resolve(response);
      })
      .catch((error) => {
        console.log("app error loginGuest:", error);
        reject(error);
      });
  });
};

export const payWithPreAuth = ({
  requestUrl,
  paymentAddress,
  hash,
  signature,
  publicKey,
}) => {
  return new Promise((resolve, reject) => {
    service
      .preAuth({
        requestUrl: requestUrl,
        paymentAddress: paymentAddress,
        hash: hash,
        signature: signature,
        publicKey: publicKey,
      })
      .then((response) => {
        console.log("app response preAuth ", response);
        resolve(response);
      })
      .catch((error) => {
        console.log("app error preAuth:", error.data);
        reject(error);
      });
  });
};

export const verifyToken = () => {
  return new Promise((resolve, reject) => {
    const accessToken = JSON.parse(localStorage.getItem("token"));
    if (accessToken) {
      service.setAccessToken(accessToken);
      service
        .verifyToken()
        .then((response) => {
          console.log("app response verifyToken ", response);
          resolve(response);
        })
        .catch((error) => {
          console.log("app error verifyToken:", error.data);
          reject(error);
        });
    }
  });
};

export const fetchProfile = () => {
  return new Promise((resolve, reject) => {
    const accessToken = JSON.parse(localStorage.getItem("token"));
    if (accessToken) {
      service.setAccessToken(accessToken);
      service
        .fetchProfile()
        .then((response) => {
          console.log("app response fetchProfile ", response);
          resolve(response);
        })
        .catch((error) => {
          console.log("app error fetchProfile:", error.data);
          reject(error);
        });
    }
  });
};

export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const accessToken = JSON.parse(localStorage.getItem("token"));
    const refreshToken = JSON.parse(localStorage.getItem("refresh-token"));
    if (accessToken && refreshToken) {
      service.setAccessToken(accessToken);
      service.setRefreshToken(refreshToken);
      service
        .refreshTokens()
        .then((response) => {
          console.log("app response refreshTokens ", response);
          service.setAccessToken(response.accessToken);
          service.setRefreshToken(response.refreshToken);
          localStorage.setItem("token", JSON.stringify(response.accessToken));
          localStorage.setItem(
            "refresh-token",
            JSON.stringify(response.refreshToken)
          );
          resolve(response);
        })
        .catch((error) => {
          console.log("app error refreshTokens:", error.data);
          reject(error);
        });
    }
  });
};

export const getInvoiceById = (response) => {
  return new Promise((resolve, reject) => {
    service
      .getInvoiceById(response)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  loadingStyle: {
    margin: "10px auto 15px auto",
    width: "24px !important",
    height: "24px !important",
    display: "block",
  },
}));

const Loading = (props) => {
  const classes = useStyles();

  return (
    <CircularProgress
      {...props}
      thickness={6}
      color="secondary"
      className={classes.loadingStyle}
    />
  );
};

export default Loading;

/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  getPayload,
  oneTimeHTML,
  loginAsGuest,
  getInvoiceById,
  getService as myService,
} from "../provider/mySabay.js";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "../constant";
import CountDownTimer from "./countDownTimer";
import CustomDialog from "../components/customDialog";
import SuccessPaymentDialog from "../components/successPaymentDialog";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthLg": {
      width: "100%",
    },
  },
  label: {
    color: "#727272",
    fontSize: Constants.fontSize.text,
    textAlign: "left",
    paddingBottom: "10px",
  },
}));

const timeoutMinute = 2;
const delayPingMilliseconds = 2000;

export default function PaymentConfirmDialogOneTime({
  openDialog,
  setOpenDialog,
  selectedPaymentMethod,
  postMessageData,
  setErrorMessage,
}) {
  const [payload, setPayload] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const timer = useRef(null);
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [openSuccessPaymentDialog, setOpenSuccessPaymentDialog] = useState(
    false
  );

  useEffect(() => {
    if (postMessageData) {
      (async () => {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const visitorId = result.visitorId;
        console.log("visitor id", visitorId);
        loginAsGuest(visitorId)
          .then((res) => {
            getPayload(
              selectedPaymentMethod.id,
              postMessageData.displayName,
              postMessageData.packageId,
              postMessageData.packageCode,
              selectedPaymentMethod.issueCurrencies[0] === "SC" ||
                selectedPaymentMethod.issueCurrencies[0] === "SG"
                ? parseFloat(
                    Math.ceil(
                      (postMessageData.amount * myService().usdkhr) / 100
                    )
                  )
                : postMessageData.amount,

              selectedPaymentMethod.issueCurrencies[0]
            )
              .then((res) => {
                setPayload(res);
                startStopPing(res);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            setOpenDialog(false);
            setErrorMessage(
              "Unexpected error while attempting to use this payment provider. Please try again."
            );
            setOpenCustomDialog(true);
          });
      })();
    }
    return () => {
      clearInterval(timer.current);
      timer.current = null;
    };
  }, []);

  const startStopPing = async (res) => {
    if (!timer.current) {
      timer.current = setInterval(() => {
        if (res) {
          checkInvoice(res);
        }
      }, delayPingMilliseconds);
    } else {
      clearInterval(timer.current);
      timer.current = null;
      if (!res?.completedPayment) {
        console.log(res);
        setOpenCustomDialog(true);
        setFeedbackMessage("Unsuccessful payment");
      }
    }
  };

  const checkInvoice = async (response) => {
    if (response) {
      const invoiceId = response.invoiceId;
      getInvoiceById(invoiceId)
        .then((response) => {
          if (response.ssnTxHash) {
            startStopPing({ completedPayment: true });
            setPaymentCompleted(true);
            setOpenSuccessPaymentDialog(true);
            myService().trackEvent({
              category: "cashier-onetime-payment-completed",
              action: "process",
            });
            setTimeout(() => {
              console.log("payment is completed");
              setOpenDialog(false);
              window.close();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("app error getInvoiceById: ", error);
        });
    }
  };

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      className={classes.root}
      disableBackdropClick={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: "#000",
            padding: "20px",
            margin: 0,
            fontSize: i18n.language == "kh" ? "14px" : "16px",
          }}
        >
          {t("To pick another payment option, please click")}{" "}
          <u
            onClick={() => {
              myService().trackEvent({
                category: "cashier-close-onetime-paymment",
                action: "tap",
              });
              setOpenDialog(false);
            }}
            style={{ cursor: "pointer" }}
          >
            {t("here")}
          </u>{" "}
          {t("and start over")}
        </p>
        {payload?.hash && (
          <CountDownTimer
            timeout={() => {
              startStopPing();
            }}
            // checkInvoice={()=>checkInvoice(payload)}
            completed={paymentCompleted}
            initialMinute={timeoutMinute}
          />
        )}
      </div>
      {payload?.hash && (
        <iframe
          id="my-iframe1"
          title="Payment provider"
          frameBorder="0"
          srcDoc={oneTimeHTML(payload)}
          style={{
            width: "100%",
            height: "80vh",
            overflow: "scroll",
            backgroundColor: "white",
          }}
        />
      )}
      <CustomDialog
        openCustomDialog={openCustomDialog}
        setOpenCustomDialog={setOpenCustomDialog}
        text={feedbackMessage}
        callback={() => setOpenDialog(false)}
      />

      <SuccessPaymentDialog
        openSuccessPaymentDialog={openSuccessPaymentDialog}
        setOpenSuccessPaymentDialog={setOpenSuccessPaymentDialog}
      />
    </Dialog>
  );
}

/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "../constant";
import { useTranslation } from "react-i18next";

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    color: Constants.color.error,
    fontSize: "14px",
    textAlign: "left",
    paddingBottom: "10px",
    marginTop: "10px",
  },
}));

export default function errorText({ text }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Typography
      className={classes.label}
      style={{ fontSize: i18n.language == "kh" && "12px" }}
    >
      {t(text)}
    </Typography>
  );
}

const env = window.RUNTIME_NODE_ENV ? window.RUNTIME_NODE_ENV : "development";

const fontSize = {
  title: 24,
  subTitle: 18,
  productTitle: 14,
  subMenu: 12,
  text: 16,
};

const fontWeight = {
  text: 500,
};

const color = {
  main: "#D63447",
  gold: "#FAB95B",
  text: "#727272",
  darkText: "#474747",
  white: "#FFFFFF",
  darkWhite: "#cccccc",
  contentBg: "#F4F4F4",
  snackBarColor: "#00000029",
  gray: "#C4C4C4",
  darkMain: "#C5202C",
  error: "#e53935",
  disabledColor: "#e4e1e1",
  disabledTextColor: "#c5c5c5",
  disabledMainColor: "#d49ea4",
  blue: "#4267B2",
  lightGray: "#7979793b",
};

const fakeProduct = {
  serviceCode: "sdk_sample",
  channel: "FROM_SABAY_PAYMENT",
  productId: "605b0eb8d5d31b09a3064087",
  displayName: "500 Diamonds",
  packageId: "kh.com.sabay.aog.local.1_usd",
  packageCode: "500_diamond",
  amount: 9.99,
  currency: "USD",
};

const Constants = {
  fontSize,
  color,
  fontWeight,
  env,
  fakeProduct,
};
export default Constants;

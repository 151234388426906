/* eslint-disable */
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Constants from "../constant";
import { useTranslation } from "react-i18next";
import { getService as myService } from "../provider/mySabay";

const useStyles = makeStyles((theme) => ({
  label: {
    color: Constants.color.main,
    fontSize: Constants.fontSize.text + 2,
    fontWeight: "600",
  },
  button: {
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
  },
}));

export default function TemporaryDrawer({ userId, callback }) {
  const { t, i18n } = useTranslation();

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const classes = useStyles();

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            id="dropdown-menu-button"
          >
            <FontAwesomeIcon
              style={{
                color: "#FFF",
                fontSize: 32,
              }}
              icon={faBars}
            />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div
              style={{
                padding: "20px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "right" }}>
                <FontAwesomeIcon
                  style={{
                    color: Constants.color.main,
                    fontSize: 32,
                    cursor: "pointer",
                  }}
                  icon={faTimes}
                  onClick={() => setState({ [anchor]: anchor, right: false })}
                />

                <hr
                  style={{
                    opacity: "0.30",
                    backgroundColor: Constants.color.gray,
                    border: "none",
                    height: "1px",
                    margin: "10px 0",
                  }}
                />

                <Typography
                  className={classes.label}
                  style={{ fontSize: i18n.language == "kh" && "16px" }}
                >
                  {t("Logged in as:")}{" "}
                  <span style={{ fontSize: "18px" }}>{userId}</span>
                </Typography>
              </div>

              <div>
                <Button
                  className={classes.button}
                  style={{
                    background: Constants.color.main,
                    color: Constants.color.white,
                    border: `1px solid ${Constants.color.main}`,
                    padding: "5px 22px",
                    whiteSpace: "nowrap",
                    textDecoration: "inherit",
                    fontSize: i18n.language == "kh" && "14px",
                  }}
                  onClick={() => {
                    myService().trackEvent({
                      category: "cashier-use-another-account",
                      action: "tap",
                    });
                    callback();
                  }}
                >
                  {t("Use Another Account")}
                </Button>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

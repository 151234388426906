/* eslint-disable */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "../constant";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthLg": {
      width: "100%",
    },
  },
  mainTitle: {
    color: "#D63447",
    fontWeight: "550",
    fontSize: Constants.fontSize.title,
    marginBottom: "20px",
  },
  button: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
    padding: "7px 30px",
    margin: "10px",
  },
}));

export default function customDialog({
  openCustomDialog,
  setOpenCustomDialog,
  text,
  callback = () => {},
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      open={openCustomDialog}
      onClose={() => setOpenCustomDialog(false)}
      className={classes.root}
      disableBackdropClick={false}
    >
      <div style={{ padding: "40px 40px 0 40px" }}>
        <Typography
          className={classes.mainTitle}
          style={{
            color: "#727272",
            fontSize: "20px",
            marginTop: "12px",
            marginBottom: 0,
          }}
        >
          {t(text)}
        </Typography>
        <div style={{ textAlign: "right", margin: "30px 0px 25px 0" }}>
          <Button
            className={classes.button}
            onClick={() => {
              setOpenCustomDialog(false);
              callback();
            }}
            style={{ fontSize: i18n.language == "kh" && "14px" }}
          >
            {t("OK")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

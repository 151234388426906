/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
} from "@material-ui/core";
import Loading from "../components/loading";
import ReactCodeInput from "react-code-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import mySabay from "../assets/images/logo/mysabay.png";
import { useHistory } from "react-router-dom";
import Constants from "../constant";
import {
  loginWithPhone,
  submitOTP,
  loginWithFacebook,
  fetchProfile,
  getService as myService,
} from "../provider/mySabay.js";
import { FacebookProvider, Login } from "react-facebook";
import { PhoneNumberUtil } from "google-libphonenumber";
import { UserContext } from "../provider/UseContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

import sabayIcon from "../assets/images/sabay_icon.svg";
import ErrorText from "../components/errorText";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 0px 5px #00000029",
    borderRadius: "15px",
    background: "#FFFFFF",
    marginBottom: "30px",
    "& .MuiCardContent-root": {
      padding: "40px",
      [theme.breakpoints.down("lg")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
      },
    },
  },
  mainTitle: {
    color: "#D63447",
    fontWeight: "550",
    fontSize: Constants.fontSize.title,
    marginBottom: "20px",
  },
  label: {
    color: "#727272",
    fontSize: Constants.fontSize.text,
    textAlign: "left",
    paddingBottom: "10px",
  },
  paymentItemContainer: {
    border: "0.5px solid #C4C4C4",
    boxShadow: "0px 0px 0px #00000029",
    borderRadius: "6px",
    cursor: "pointer",
  },
  paymentItemContainerNest: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    paddingLeft: 8,
    fontSize: Constants.fontSize.text,
  },
  textfield: {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      boxShadow: "0px 0px 5px #00000029",
    },
  },
  button: {
    fontWeight: "bold",
    minWidth: "150px",
    textTransform: "uppercase",
    color: Constants.color.white,
    fontSize: Constants.fontSize.text,
    background: Constants.color.main,
    borderRadius: "31px",
    marginTop: "15px",
    "&:hover": {
      background: Constants.color.darkMain,
    },
    padding: "5px 20px",
  },
  loadingStyle: {
    margin: "10px auto 15px auto",
    width: "24px !important",
    height: "24px !important",
    display: "block",
  },
  mySabayButton: {
    margin: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: `1px solid ${Constants.color.main}`,
    padding: "8px 25px",
    borderRadius: "24px",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: Constants.color.main,
    },
    "&:hover img": {
      transition: "0.3s",
      filter: "brightness(0) invert(1)",
    },
    "&:hover p": {
      transition: "0.3s",
      color: "#FFF !important",
    },
  },
  facebookButton: {
    margin: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: `1px solid ${Constants.color.blue}`,
    padding: "8px 25px",
    borderRadius: "24px",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: Constants.color.blue,
    },
    "&:hover svg": {
      transition: "0.3s",
      color: "#FFF !important",
    },
    "&:hover p": {
      transition: "0.3s",
      color: "#FFF !important",
    },
  },
}));

const PhoneNumberLogin = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const isXs = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [canResend, setCanResend] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  // const [openPaymentConfirmDialog, setOpenPaymentConfirmDialog] = useState(false);

  const history = useHistory();

  const {
    paymentProviderLists,
    setPaymentProviderLists,
    postMessageData,
    setPostMessageData,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setUserProfile,
    setIsLogin,
    displayLogin,
    setDisplayLogin,
    openDialogPreAuth,
    setOpenDialogPreAuth,
  } = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => {
      myService().trackPageView({
        documentTitle: "Cashier - Login with phone number",
      });
    }, 1000);
  }, []);

  const handleLoginPhoneNumber = (phoneNumber, { countryCode }) => {
    setValidationMessage("");

    if (phoneNumber === "") {
      setValidationMessage("please input phone number");
    } else {
      setLoading(true);
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
      const isValid = phoneUtil.isValidNumber(number);

      const phoneNumberFormatted =
        number.getCountryCode().toString() +
        number.getNationalNumber().toString();

      if (isValid && phoneNumber.length > 0) {
        loginWithPhone(phoneNumberFormatted)
          .then((res) => {
            console.log(res);
            setLoading(false);
            setDisplayLogin(false);
            localStorage.setItem("tmp-phone", phoneNumberFormatted);
            setCanResend(false);
          })
          .catch((err) => {
            alert(err.message);
            setLoading(false);
          });
      } else {
        setLoading(false);
        setValidationMessage("phone number is not valid");
      }
    }
  };

  const fetchUserProfile = () => {
    fetchProfile()
      .then((res) => {
        setUserProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitOTP = (code) => {
    setValidationMessage("");
    setOtp(code);
    if (code.length == 6) {
      setLoading(true);
      submitOTP(code)
        .then((res) => {
          setLoading(false);
          myService().trackEvent({
            category: "cashier-login-by-phone-input-otp",
            action: "tap",
          });
          if (history.location.state == "FROM_USE_ANOTHER_ACCOUNT_BTN") {
            history.push("/");
            fetchUserProfile();
            setIsLogin(true);
          } else {
            setOpenDialogPreAuth(true);
            fetchUserProfile();
            history.push("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          setValidationMessage("Incorrect OTP");
        });
    }
  };

  const handleLoginFacebook = (facebookResponse) => {
    loginWithFacebook(facebookResponse)
      .then(() => {
        setOpenDialogPreAuth(true);
        fetchUserProfile();
        setIsLogin(true);
        history.push("/");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const callTime = () => {
    setCanResend(true);
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      {displayLogin && (
        <Card className={classes.card}>
          <CardContent style={{ textAlign: "center" }}>
            <img src={mySabay} style={{ width: "75px", borderRadius: "50%" }} />
            <Typography
              className={classes.mainTitle}
              style={{
                fontSize: i18n.language == "kh" ? "28px" : "30px",
                marginTop: "10px",
              }}
            >
              {t("Sign In")}
            </Typography>
            <div
              className={classes.itemCenter}
              style={{ width: "100%", flexFlow: "column" }}
            >
              <PhoneInput
                disableCountryGuess={true}
                disableCountryCode={true}
                country={"kh"}
                prefix={""}
                value={phoneNumber}
                onChange={(phoneNumber, countryCode) => {
                  setPhoneNumber(phoneNumber);
                  setCountryCode(countryCode);
                }}
                placeholder="0XX XXX XXX"
                containerStyle={{
                  height: isXs ? "40px" : "50px",
                  width: "100%",
                  borderRadius: 10,
                  marginTop: 8,
                }}
                inputStyle={{
                  height: "100%",
                  width: "100%",
                  border: 0,
                  border: `1px solid ${Constants.color.lightGray}`,
                  fontSize: "21px",
                  fontFamily: "Rajdhani",
                  color: Constants.color.text,
                  fontWeight: 600,
                }}
                dropdownStyle={{
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
                  fontFamily: "Rajdhani",
                }}
                buttonStyle={{
                  border: `1px solid ${Constants.color.lightGray}`,
                }}
                onEnterKeyPress={() =>
                  handleLoginPhoneNumber(phoneNumber, countryCode)
                }
                inputProps={{
                  autoFocus: true,
                  id: "phone-number-input",
                }}
              />

              {validationMessage && <ErrorText text={validationMessage} />}
            </div>

            {loading && <Loading />}

            <div style={{ textAlign: "right" }}>
              <Button
                id="continue-button"
                className={classes.button}
                onClick={() => {
                  handleLoginPhoneNumber(phoneNumber, countryCode);
                  myService().trackEvent({
                    category: "cashier-continue-login",
                    action: "tap",
                  });
                }}
                style={{
                  width: isXs ? "100%" : "auto",
                  fontSize: i18n.language == "kh" && "14px",
                }}
              >
                {t("CONTINUE")}
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "25px 0",
              }}
            >
              <hr
                style={{
                  opacity: "0.80",
                  backgroundColor: Constants.color.gray,
                  border: "none",
                  height: "1px",
                  width: isXs ? "50%" : "100%",
                }}
              />
              <Typography
                className={classes.label}
                style={{
                  fontSize: i18n.language == "kh" && "14px",
                  padding: 0,
                  textAlign: "center",
                  fontWeight: 600,
                  display: "inline-block",
                  width: "85%",
                }}
              >
                {t("Or Login With")}
              </Typography>
              <hr
                style={{
                  opacity: "0.80",
                  backgroundColor: Constants.color.gray,
                  border: "none",
                  height: "1px",
                  width: isXs ? "50%" : "100%",
                }}
              />
            </div>

            <Grid container>
              <Grid item md={6} xs={12}>
                <div
                  className={classes.mySabayButton}
                  id="mysabay-login-button"
                  onClick={() => {
                    myService().trackEvent({
                      category: "cashier-open-login-by-mysabay",
                      action: "tap",
                    });
                    history.push("/my-sabay-login");
                  }}
                >
                  <img
                    src={sabayIcon}
                    tyle={{ width: "18px", padding: "0 6px" }}
                    className={classes.sabayIcon}
                  />
                  <Typography
                    className={classes.label}
                    style={{
                      fontWeight: 700,
                      padding: 0,
                      marginLeft: "10px",
                      color: Constants.color.main,
                    }}
                  >
                    MYSABAY
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <FacebookProvider appId="587695748053859">
                  <Login
                    scope="email"
                    onCompleted={(facebookResponse) => {
                      handleLoginFacebook(facebookResponse);
                      myService().trackEvent({
                        category: "cashier-login-by-facebook",
                        action: "tap",
                      });
                    }}
                    onError={() => console.log("err")}
                  >
                    {({ loading, handleClick, error, data }) => (
                      <div
                        className={classes.facebookButton}
                        onClick={handleClick}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: Constants.color.blue,
                            fontSize: 17,
                            padding: "0 6px",
                          }}
                          icon={faFacebookF}
                        />

                        <Typography
                          className={classes.label}
                          style={{
                            fontWeight: 700,
                            padding: 0,
                            marginLeft: "10px",
                            color: Constants.color.blue,
                          }}
                        >
                          FACEBOOK
                        </Typography>
                      </div>
                    )}
                  </Login>
                </FacebookProvider>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {!displayLogin && (
        <Card className={classes.card}>
          <CardContent style={{ textAlign: "center" }}>
            <img src={mySabay} style={{ width: "75px", borderRadius: "50%" }} />
            <Typography
              className={classes.mainTitle}
              style={{
                fontSize: i18n.language == "kh" ? "28px" : "30px",
                marginTop: "10px",
              }}
            >
              {t("Confirm OTP")}
            </Typography>

            <ReactCodeInput
              type="text"
              value={otp}
              fields={6}
              onChange={(value) => handleSubmitOTP(value)}
            />

            {validationMessage && (
              <Typography
                className={classes.label}
                style={{
                  fontWeight: 550,
                  color: Constants.color.main,
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                {validationMessage}
              </Typography>
            )}

            {loading && <Loading />}

            <div>
              <p style={{ color: "#838383", marginBottom: 8, marginTop: 32 }}>
                {t("Didn’t get the OTP?")}
              </p>
              {!canResend && <TimerCountDown callTime={callTime} />}

              {canResend && (
                <p
                  onClick={() =>
                    handleLoginPhoneNumber(phoneNumber, countryCode)
                  }
                  style={{
                    color: "#EF495B",
                    marginBottom: 8,
                    cursor: canResend ? "pointer" : "not-allowed",
                  }}
                >
                  <u id="resend-otp-testing">{t("Resend OTP")}</u>
                </p>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default PhoneNumberLogin;

const TimerCountDown = ({ callTime }) => {
  const [timeLeft, setTimeLeft] = useState(59);
  const { t } = useTranslation();
  useEffect(() => {
    // exit early when we reach 0

    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      if (timeLeft <= 1) {
        callTime();
      }
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
    // eslint-disable-next-line
  }, [timeLeft]);

  return (
    <p style={{ color: "#838383", marginBottom: 8 }}>
      {t("You can resend OTP in")} 0:
      {timeLeft.toString().length === 1 ? "0" + timeLeft : timeLeft}
    </p>
  );
};
